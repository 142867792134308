<template>
  <v-menu
    :nudge-bottom="5"
    offset-y>
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex align-center">
        <img
          src="@/assets/icons/sliders-icon.svg"
          height="24"
          width="24"
          v-bind="attrs"
          v-on="on">
      </div>
    </template>
    <v-list>
      <v-list-item
        v-for="item in sortItems"
        :key="item.request"
        :class="{'v-item--active v-list-item--active': item.request === defaultItem.request }"
        @click="$emit('resort', item)">
        <v-list-item-title class="pointer">
          {{ item.name }}
          <v-icon
            v-if="item.arrowIcon"
            color="black"
            x-small>
            {{ item.arrowIcon }}
          </v-icon>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'AppSortV2',
  props: {
    defaultItem: {
      type: Object,
      default: null,
    },
    sortItems: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
</script>
